<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Tabulator - Developer Documentation" />
    <!--EOC-->
    <v-row>
      <v-col cols="12" sm="3">
        <TheLeftSidebar :leftMenuItems="leftmenu"></TheLeftSidebar>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card min-height="70vh" rounded="lg">
          <v-card-title>Search and Filter</v-card-title>
          <v-card-text>
            <p>Example Table With Filter</p>

            <BreadTabulatorAdvanceFilterable
            ref="advanceFilter"
            :model="model"
            :setAdvancedFilterConditions="setAdvancedFilterConditions"
            ></BreadTabulatorAdvanceFilterable>

            <BreadTabulatorSearchable
              ref="search"
              :model="model"
              :setConditions="setConditions"
            ></BreadTabulatorSearchable>

            <BreadTabulatorFilterable
              ref="filter"
              :model="model"
              :setConditions="setConditions"
            ></BreadTabulatorFilterable>

            <div class="px-3 py-1 border-b text-caption">
              {{ conditions.length }} filters.
            </div>

            <BreadTabulatorSelectColumn
              ref="selectColumn"
              :model="model"
              @applyColumnSelection="applyColumnSelection"
            ></BreadTabulatorSelectColumn>

            <BreadTabulatorView
            ref="customView"
            :model = "model"
            :selectedCustomColums="this.selectedCustomColums"
            :selectedAdvanceFilterConditions="this.selectedAdvanceFilterConditions"
            :selectedFilterConditions="this.selectedFilterConditions"
            :listAllViews="this.listAllViews"
            @changeTableDetails="changeTableDetails"
            ></BreadTabulatorView>

            <div>
            <div id="pagination-counter" style="float: left; margin-right: 2px;"></div>
            <span id="record-count" style="float: left;font-size: 14px;"></span>
            <div style="clear: both;"></div>
          </div>

            <BreadTabulator
              ref="tabulatorDataTable"
              layout="fitColumns"
              :autoColumns="false"
              :paginationSize="this.paginationSize"
              :paginationSizeSelector="this.paginationSizeSelector"
              :apiUrl="this.apiGetBrowseUrl()"
              :columns="this.columns"
              modelKey="demoProduct"
            ></BreadTabulator>

            <p>
              we are going to use the common component
              (BreadTabulatorFilterable.vue) to create the filters.
            </p>
            <p>we have to import the component inside the main.js file</p>

            <CopyToClipBoard
              :text-to-copy="scriptSectionGlobalImport"
            ></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>
                
                    {{ scriptSectionGlobalImport }}
                  </pre
              >
            </VueCodeHighlight>

            <p>2. Call the common component inside the template section</p>
            <CopyToClipBoard :text-to-copy="templateSection"></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                
                    {{ templateSection }}
                  </pre
              >
            </VueCodeHighlight>
            <p>3. After calling the filter component inside the template, it will include following fields and props <br>
               - fields
              <ul>
                <li>Table columns</li>
                <li>Type (Condition)</li>
                <li>Value</li>
              </ul>
              
              - Props
              <ul>
                <li>model</li>
                <li>setConditions</li>
              </ul>
            </p>

            <p>4. Fields</p>

            <p>Table Columns - This columns will load from model->headers array. it will load only filterable:true columns name and for seach it will load searchable: true column names</p>
            <CopyToClipBoard
              :text-to-copy="scriptModelColumns"
            ></CopyToClipBoard>
            <VueCodeHighlight>
              <pre> 
                {{ scriptModelColumns }}
              </pre>
            </VueCodeHighlight>

            <p>Type (Condition) - This field will load from filterTypes array. This value will change based on the column dataType</p>

            <CopyToClipBoard
              :text-to-copy="scriptModelType"
            ></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                {{ scriptModelType }}
              </pre>
            </VueCodeHighlight>

            <p>Following method will change the type value based on the column type </p>

            <CopyToClipBoard
              :text-to-copy="scriptModelTypeMethod"
            ></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                {{ scriptModelTypeMethod }}
              </pre>
            </VueCodeHighlight>

            <p>Value - input type will change according to the column type. Please reffer previous code. </p>

            <h5>Filter Component Complete Code: (BreadTabulatorFilterable.vue)</h5>

            <h6>Template Section: </h6>

            <CopyToClipBoard
              :text-to-copy="filterComponentTemplateSection"
            ></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                {{ filterComponentTemplateSection }}
              </pre>
            </VueCodeHighlight>

            <h6>Script Section: </h6>

            <CopyToClipBoard
              :text-to-copy="filterComponentScriptSection"
            ></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                {{ filterComponentScriptSection }}
              </pre>

              Code REPLACE ONE = `${selectedColumn.field} ${this.type} ${this.value}`
              Code REPLACE TWO =  `${condition.field} ${condition.type} ${condition.value}`

            </VueCodeHighlight>

            <h5>Add following methods inside the vue file (the file which are using to display table)</h5>

            <CopyToClipBoard
              :text-to-copy="methodsToFilterData"
            ></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                {{ methodsToFilterData }}
              </pre>
            </VueCodeHighlight>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
import TheLeftSidebar from "../components/PageDeveloperTabulator/TheLeftSidebar.vue";
import leftmenu from "../config/leftmenu.js";
import Service from "@/objects/service";
import CopyToClipBoard from "../components/PageDeveloperTabulator/CopyToClipBoard.vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import BreadTabulatorView from "../../../components/common/BreadTabulatorView.vue";
import "vue-code-highlight/themes/prism-okaidia.css";
/* import "vue-code-highlight/themes/window.css"; */
//BOC:[model]
import Model from "@/objects/model";
//EOC
import { mapState } from "vuex";

import Api from "@/objects/api";

export default {
  components: {
    TheLeftSidebar,
    CopyToClipBoard,
    VueCodeHighlight,
    BreadTabulatorView,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    leftmenu: leftmenu,

    listAllViews: [],

    //BOC:[service]
    service: new Service(),
    //EOC

    //BOC:[model]
    model: new Model(),
    //EOC

    filters: [],

    conditions: [],

    api: new Api(),

    selectedCustomColums: [],
    selectedAdvanceFilterConditions: [],
    selectedFilterConditions: [],

    scriptSectionGlobalImport: `
    Vue.component('BreadTabulatorFilterable', require('./components/common/BreadTabulatorFilterable.vue').default); 
    Vue.component('BreadTabulatorSearchable', require('./components/common/BreadTabulatorSearchable.vue').default);`,
    templateSection: `
    <BreadTabulatorFilterable
            ref="filter"
            :model="model"
            :setConditions="setConditions"
          ></BreadTabulatorFilterable>
    
          <BreadTabulatorSearchable
              ref="search"
              :model="model"
              :setConditions="setConditions"
            ></BreadTabulatorSearchable>
    `,

    scriptModelColumns: `
    headers: [
        {
          title: "ID",
          field: "id",
          sortable: false,
          searchable: false,
          filterable: false,
        },
        {
          title: "Name",
          field: "name",
          dataType: "string",
          searchable: true,
          sortable: false,
          filterable: true,
        },
        {
          title: "Cost",
          field: "cost",
          dataType: "float",
          editableSingleCell: true,
          filterable: true,
          searchable: true,
        },
        {
          title: "Price",
          field: "price",
          dataType: "float",
          editableSingleCell: true,
          filterable: true,
          searchable: true,
        },

        {
          title: "Stock Count",
          field: "stock",
          dataType: "integer",
          editableSingleCell: true,
          filterable: true,
          searchable: true,
        },

        {
          title: "Availability",
          field: "instock",
          dataType: "boolean",
          editableSingleCell: true,
          filterable: false,
          searchable: false,
        },

        {
          title: "Rating",
          field: "rating",
          dataType: "float",
          editableSingleCell: true,
          filterable: false,
          searchable: false,
        },

      ],`,

    scriptModelType: `
      filterTypes: [
      {
        text: "Equal",
        value: "=",
      },
      {
        text: "Not Equal",
        value: "!=",
      },
      {
        text: "Like",
        value: "like",
      },
      {
        text: "Keywords",
        value: "keywords",
      },
      {
        text: "Starts With",
        value: "starts",
      },

      {
        text: "Ends With",
        value: "ends",
      },

      {
        text: "Less Than",
        value: "<",
      },

      {
        text: "Less Than Or Equal To",
        value: "<=",
      },

      {
        text: "Greater Than",
        value: ">",
      },

      {
        text: "Greater Than Or Equal To",
        value: ">=",
      },
    ],`,

    scriptModelTypeMethod: `
    changeFilterType() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },

            {
              text: "Less Than",
              value: "<",
            },

            {
              text: "Less Than Or Equal To",
              value: "<=",
            },

            {
              text: "Greater Than",
              value: ">",
            },

            {
              text: "Greater Than Or Equal To",
              value: ">=",
            },
          ];
          this.valueInputType = "number";
          break;
        case "integer":
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },

            {
              text: "Less Than",
              value: "<",
            },

            {
              text: "Less Than Or Equal To",
              value: "<=",
            },

            {
              text: "Greater Than",
              value: ">",
            },

            {
              text: "Greater Than Or Equal To",
              value: ">=",
            },
          ];
          this.valueInputType = "number";
          break;
        case "boolean":
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },
          ];
          this.valueInputType = "text";
          break;

        default:
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },
            {
              text: "Like",
              value: "like",
            },
            {
              text: "Keywords",
              value: "keywords",
            },
            {
              text: "Starts With",
              value: "starts",
            },

            {
              text: "Ends With",
              value: "ends",
            },

            {
              text: "Less Than",
              value: "<",
            },

            {
              text: "Less Than Or Equal To",
              value: "<=",
            },

            {
              text: "Greater Than",
              value: ">",
            },

            {
              text: "Greater Than Or Equal To",
              value: ">=",
            },
          ];
          this.valueInputType = "text";
          break;
      }
    },`,

    filterComponentTemplateSection:`
    <template>
  <div class="border-b py-1 px-3">
    <div class="pt-1">
      <b>Filters</b>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span
          >You may apply multiple filters to select the data that you will like
          to view.</span
        >
      </v-tooltip>
    </div>
    <div class="py-1 d-flex align-center">
      <div class="d-flex" style="width: calc(100% - 100px)">
        <v-select
          v-model="column_name"
          :items="columns"
          item-text="title"
          item-value="field"
          label="Table Column"
          outlined
          dense
          hide-details
          class="mr-1"
          style="width: 33.3%"
          @change="changeFilterType"
        ></v-select>
        <v-select
          v-model="type"
          :items="this.filterTypes"
          item-text="text"
          label="Type"
          outlined
          dense
          hide-details
          class="mr-1"
          style="width: 33.3%"
        ></v-select>
        <v-text-field
          v-model="value"
          label="Value"
          :type="this.valueInputType"
          outlined
          dense
          hide-details
          class="mr-1"
          style="width: 33.3%"
          @keyup.native.enter="addFilter"
        ></v-text-field>
      </div>
      <v-btn
        :disabled="!value"
        @click="addFilter"
        dense
        depressed
        style="border: solid thin rgba(0, 0, 0, 0.12) !important; width: 100px"
      >
        Add
      </v-btn>
    </div>
    <div class="py-1 d-flex align-center">
      <v-select
        v-model="selectedFilters"
        prepend-icon="mdi-filter"
        :items="filters"
        label="Filter"
        no-data-text="No filters."
        outlined
        dense
        hide-details
        multiple
        class="mx-1"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 3" color="yellow" small>
            <span>{{ item.text }}</span>
          </v-chip>
          <span v-if="index === 3" class="grey--text text-caption">
            (+{{ selectedFilters.length - 3 }} others)
          </span>
        </template>
      </v-select>
      <v-btn
        @click="clearFilter"
        :disabled="filters.length < 1"
        dense
        depressed
        style="
          border: solid thin rgba(0, 0, 0, 0.12) !important;
          min-width: 100px;
        "
      >
        Clear
      </v-btn>
    </div>
  </div>
</template>`,

filterComponentScriptSection:`
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    model: {
      type: Object,
      required: true,
    },
    setConditions: {
      type: Function,
    },
  },
  data: () => ({
    column_name: null,
    columns: [],
    filters: [],
    type: null,
    selectedFilters: [],
    value: null,
    filterTypes: [
      {
        text: "Equal",
        value: "=",
      },
      {
        text: "Not Equal",
        value: "!=",
      },
      {
        text: "Like",
        value: "like",
      },
      {
        text: "Keywords",
        value: "keywords",
      },
      {
        text: "Starts With",
        value: "starts",
      },
      {
        text: "Ends With",
        value: "ends",
      },

      {
        text: "Less Than",
        value: "<",
      },

      {
        text: "Less Than Or Equal To",
        value: "<=",
      },

      {
        text: "Greater Than",
        value: ">",
      },

      {
        text: "Greater Than Or Equal To",
        value: ">=",
      },
    ],

    valueInputType: "text",
  }),
  watch: {
    selectedFilters() {
      this.updateConditions();
    },
  },
  created() {
    this.columns = this.$_.filter(this.model.browse.table.headers, (o) => {
      if (o.filterable != false) {
        return o;
      }
    });
    this.column_name = this.columns[0] ? this.columns[0].field : null;
    this.type = "=";
    this.resetCondition();
  },
  mounted() {
    //
  },
  methods: {
    addFilter() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.value = parseFloat(this.value);
          break;
        case "integer":
          this.value = parseInt(this.value);
          break;
        case "boolean":
          this.value = this.value == "true" ? true : false;
          break;
      }

      var condition = {
        field: selectedColumn.field,
        type: this.type,
        value: this.value,
      };
      var filter = {
        text: REPLACE ONE,
        value: JSON.stringify(condition),
      };
      //BOC:check duplicated filter
      if (
        this.$_.findIndex(this.selectedFilters, { value: filter.value }) > -1
      ) {
        alert("Same filter already existed.");
        return;
      }
      //EOC
      this.filters.push(filter);
      this.selectedFilters.push(filter.value);
      this.resetCondition();
    },
    clearFilter() {
      this.filters = [];
      this.selectedFilters = [];
    },
    setFiltersViaConditions(conditions) {
      if (!conditions) return;
      var filters = [];
      for (var condition of conditions) {
        var filter = {
          text: REPLACE TWO,
          value: JSON.stringify(condition),
        };
        filters.push(filter);
      }
      this.filters = filters;
      this.selectedFilters = this.$_.map(filters, "value");
    },
    updateConditions() {
      var conditions = [];
      for (var condition of this.selectedFilters) {
        conditions.push(JSON.parse(condition));
      }
      this.setConditions(conditions);
    },
    resetCondition() {
      this.value = null;
    },

    changeFilterType() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },

            {
              text: "Less Than",
              value: "<",
            },

            {
              text: "Less Than Or Equal To",
              value: "<=",
            },

            {
              text: "Greater Than",
              value: ">",
            },

            {
              text: "Greater Than Or Equal To",
              value: ">=",
            },
          ];
          this.valueInputType = "number";
          break;
        case "integer":
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },

            {
              text: "Less Than",
              value: "<",
            },

            {
              text: "Less Than Or Equal To",
              value: "<=",
            },

            {
              text: "Greater Than",
              value: ">",
            },

            {
              text: "Greater Than Or Equal To",
              value: ">=",
            },
          ];
          this.valueInputType = "number";
          break;
        case "boolean":
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },
          ];
          this.valueInputType = "text";
          break;

        default:
          this.filterTypes = [
            {
              text: "Equal",
              value: "=",
            },
            {
              text: "Not Equal",
              value: "!=",
            },
            {
              text: "Like",
              value: "like",
            },
            {
              text: "Keywords",
              value: "keywords",
            },
            {
              text: "Starts With",
              value: "starts",
            },

            {
              text: "Ends With",
              value: "ends",
            },

            {
              text: "Less Than",
              value: "<",
            },

            {
              text: "Less Than Or Equal To",
              value: "<=",
            },

            {
              text: "Greater Than",
              value: ">",
            },

            {
              text: "Greater Than Or Equal To",
              value: ">=",
            },
          ];
          this.valueInputType = "text";
          break;
      }
    },
  },
};`,


methodsToFilterData:`
setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    //EOC
    //BOC:[conditions]
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.conditions = conditions;
      //var that = this;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },`,

    paginationSizeSelector: [10, 20, 30, 40, 50],
    paginationSize: 10,
    columns: [],
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Filter",
      to: { name: "" },
      exact: true,
    });
    //EOC

    //BOC:[model]
    this.model.getByKey("demoProduct");
    //EOC
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    this.columns = this.$_.filter(
        this.model.browse.table.headers,
        (headers) => {
          return headers;
        }
      );

      var columnIndex = 0;
      for (var column of this.columns) {
        if (column.dataType == "modelParent") {
          /* var parentColumnName = column.field; */
          var parentModel = new Model();
          parentModel.getByKey(column.modelKey);
          this.columns[columnIndex].formatter = (cell) => {
            var parent = cell.getRow().getData()[this.$_ucfirst(parentModel.key)];
            var parentLabel = parent.id;
            if (parent.name) parentLabel = parent.name;
            if (parentModel.column && parentModel.column.text)
              parentLabel = parent[parentModel.column.text];
  
            return `<span style="color:blue;">${parentLabel}</span>`;
          };
  
          this.columns[columnIndex].cellClick = (e, cell) => {
            var parent = cell.getRow().getData()[this.$_ucfirst(parentModel.key)];
            var parentLabel = parent.id;
            this.$router.push({
              name: "PageDeveloperComponentBreadTabulatorBrowseParent",
              params: {
                modelKey: parentModel.key,
                modelId: parentLabel,
                childModelKey: this.model.key,
              },
            });
          };
        }
        columnIndex++;
      }
      //EOC

      this.columns.push({
        title: "Actions",
        filterable: false,
        headerSort: false,
        editor: "list",
        editorParams: {
          valuesLookup: () => {
            if (this.userRole === "admin") {
              return [
                {
                  label: "View",
                  value: "view",
                },
                {
                  label: "Edit",
                  value: "edit",
                },
                {
                  label: "Delete",
                  value: "delete",
                },
              ];
            } else if (this.userRole === "user") {
              return [
                {
                  label: "View",
                  value: "view",
                },
              ];
            }
          },
        },
        formatter: () => {
          return `<span class="">Action</span>`;
        },
  
        cellEdited: (cell) => {
          var cellValue = cell.getValue();
          switch (cellValue) {
            case "view":
              this.$router.push({
                name: "PageDeveloperComponentBreadTabulatorColumnsView",
                params: {
                  columnKey: cell.getRow().getData().id,
                },
              });
              break;
            case "edit":
              alert("Editing row data");
  
              break;
            case "delete":
              alert("Deleting row data");
              break;
            default:
              break;
          }
        },
      });
    
  },
  mounted() {
    //list all views
    this.getAllViews();
  },
  methods: {
    apiGetBrowseUrl() {
      return `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/browseList`;
    },

    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    //EOC
    //BOC:[conditions]
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },

    setAdvancedFilterConditions(advanceFilterParams) {
      this.selectedAdvanceFilterConditions = advanceFilterParams;
       this.$refs.tabulatorDataTable.setAdvancedFilterConditions(advanceFilterParams);
    },

    applyColumnSelection(selectedColumns) {
        this.selectedCustomColums = selectedColumns;
        /* console.log("selectedColumns2", selectedColumns); */
        this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },

    changeTableDetails(listAllViews, viewId) {
      // get the view details from listAllViews where id is equal to viewId
      if (viewId !== "all") {
          const singleViewDetail = listAllViews.filter((view) => {
            return view.id === viewId;
          });
  
          if (singleViewDetail.length > 0) {
            this.$refs.selectColumn.showViewSelectedColumns(
              JSON.parse(singleViewDetail[0].cColumnCondition)
            );
  
            this.$refs.filter.setFiltersViaConditions(
              JSON.parse(singleViewDetail[0].filterCondition)
            );
  
            /* console.log((singleViewDetail[0].aFilterCondition));
                  
                  this.$refs.tabulatorDataTable.setAdvanceFilterViewConditions(
                  singleViewDetail[0].aFilterCondition
                  );   */
  
            //console.log(singleViewDetail[0].aFilterCondition);
  
            //this.$refs.search.setSearchValue(singleViewDetail[0].cSearchCondition);
  
            /* this.$refs.advanceFilter.setAdvanceFilterViewConditions(
                  JSON.parse(singleViewDetail[0].aFilterCondition)
                  );  */
          } else {
            this.$refs.selectColumn.showViewSelectedColumns([]);
            this.$refs.filter.setFiltersViaConditions([]);
          }
        } else {
          this.$refs.selectColumn.showAllColumns([]);
          this.$refs.filter.setFiltersViaConditions([]);
        }
    },

    //EOC
      getAllViews() {
        this.api.setMethod(`GET`);
        this.api.setUrl(
          `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.key}`
        );
        this.api.setCallbackCompleted((response) => {
          this.listAllViews = response;
          // get latest view id from the listAllViews
          //const latestViewId = this.listAllViews[0].id;
          this.changeTableDetails(this.listAllViews, "all");

        });
        this.api.fetch();
      },


  },
};
</script>

<style scoped>
</style>